export const saveAuthTokenInLocalStorage = (token: string) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(
      'token',
      token
    );
  }
};

export const loadAuthTokenFromLocalStorage = (): string | null => {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem('token');
  }
  
  return null;
};

export const removeAuthTokenFromStorage = () => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem('token');
  }
};
