import {
  CreateUserAdminDTO, CreateUserDTO, UpdateUserTokenDTO,
  UpdateUserAdminDTO, UpdateUserRegularDTO,
  UpsertUserStateRatesDTO,
  UserDTO,
  UserQueryDTO,
  UserStateRatesDTO,
  UsersResponse, UserStateSettingsDTO, UpsertUserStateSettingsDTO
} from 'dtos/user';
import RequestService from 'services/Request';
import qs from 'query-string';
import { isUndefined, negate, pickBy } from 'lodash';

class _UserService {
  getMe(): Promise<UserDTO> {
    return RequestService.client.get<UserDTO>('/user/me')
      .then((res) => res.data);
  }

  triggerSendingVerificationEmail(): Promise<void> {
    return RequestService.client.post<void>('/user/send-verification-email')
      .then((res) => res.data);
  }

  updateMe(data: UpdateUserRegularDTO): Promise<UserDTO> {
    return RequestService.client.patch<UserDTO>('/user/me', data)
      .then((res) => res.data);
  }

  updateMeToken(data: UpdateUserTokenDTO): Promise<UserDTO> {
    return RequestService.client.patch<UserDTO>('/user/me/token', data)
      .then((res) => res.data);
  }

  getById(id: string): Promise<UserDTO> {
    return RequestService.client.get<UserDTO>(`/user/${id}`)
      .then((res) => res.data);
  }

  getAllUsers(query: UserQueryDTO): Promise<UsersResponse> {
    const params = qs.stringify({ ...pickBy(query) }); // Filter out undefined values
    return RequestService.client.get<UsersResponse>(`/user?${params}`).then((res) => res.data);
  }

  updateAdminUserById(id: string, data: UpdateUserAdminDTO): Promise<UserDTO> {
    return RequestService.client.patch<UserDTO>(
      `/user/${id}`,
      pickBy(data, negate(isUndefined)) // filter out undefined values, but preserve null values
    )
      .then((res) => res.data);
  }

  createUserAdmin(data: CreateUserAdminDTO): Promise<UserDTO> {
    return RequestService.client.post<UserDTO>('/user', data)
      .then((res) => res.data);
  }

  signup(data: CreateUserDTO): Promise<UserDTO> {
    return RequestService.client.post<UserDTO>('/user/public', data)
      .then((res) => res.data);
  }

  getUserById(id: string): Promise<UserDTO> {
    return RequestService.client.get<UserDTO>(`/user/${id}`)
      .then((res) => res.data);
  }

  getUserStateRatesById(id: string): Promise<UserStateRatesDTO[]> {
    return RequestService.client.get<UserStateRatesDTO[]>(`/user/${id}/state-rates`)
      .then((res) => res.data);
  }

  getUserStateSettingsById(id: string): Promise<UserStateSettingsDTO[]> {
    return RequestService.client.get<UserStateSettingsDTO[]>(`/user/${id}/state-settings`)
      .then((res) => res.data);
  }

  upsertUserStateRatesById(userId: string, filingStateId: string, type: 'nb' | 'ed', data: UpsertUserStateRatesDTO): Promise<UserStateRatesDTO> {
    return RequestService.client.put<UserStateRatesDTO>(
      `/user/${userId}/state-rates/${filingStateId}/${type}`,
      pickBy(data, negate(isUndefined)) // filter out undefined values, but preserve null values
    )
      .then((res) => res.data);
  }

  upsertUserStateSettingsById(userId: string, filingStateId: string, data: UpsertUserStateSettingsDTO): Promise<UserStateSettingsDTO> {
    return RequestService.client.put<UserStateSettingsDTO>(
      `/user/${userId}/state-settings/${filingStateId}`,
      pickBy(data, negate(isUndefined)) // filter out undefined values, but preserve null values
    )
      .then((res) => res.data);
  }

  requestPasswordReset(email: string): Promise<void> {
    return RequestService.client.post<void>('/user/send-password-reset-email', { email })
      .then((res) => res.data);
  }
}

const UserService = new _UserService();

export default UserService;
