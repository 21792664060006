import React from 'react';
import cx from 'classnames';

interface Props extends React.ButtonHTMLAttributes<unknown> {
  children: React.ReactNode;
  onClick?: () => void;
  loading?: boolean;
  variant?: 'accent' | 'dimmed-white' | 'dark';
}

export const Button = ({ children, onClick, variant, loading, ...restProps }: Props) => {
  let variantStyles = 'bg-accent text-white hover:bg-accentDark';

  if(variant === 'dimmed-white') {
    variantStyles = 'bg-white/20 text-white hover:bg-white/40';
  }

  if(variant === 'dark') {
    variantStyles = 'bg-main text-white hover:bg-gray4';
  }

  return (
    <button
      {...restProps}
      className={cx("h-[36px] flex items-center gap-2 transition-all border-none px-5 py-2 rounded-[4px] cursor-pointer", restProps.className, variantStyles)}
      onClick={onClick}
    >
      {loading ? 'loading...' : children}
    </button>
  );
}
