import { FeeType, FilingStateDTO, TaxType } from 'dtos/filing';
import { CompanyDTO } from './company';

export enum UserSortBy {
  NAME = 'name',
  EMAIL = 'email',
  INVOICEABLE_STATUS = 'invoiceable_status',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  REGULAR = 'REGULAR',
}

export interface CreateUserDTO {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  agency: string;
  addressStreet: string;
  addressUnit?: string | null;
  addressCity: string;
  addressZipCode: string;
  addressState: string;
  phone: string | null;
}

export interface CreateUserAdminDTO {
  email: string;
  password?: string;
  firstName: string;
  lastName: string;
  avatarUrl?: string;
  agency: string;
  sendPasswordResetLink: boolean;
  addressStreet: string | null;
  addressUnit: string | null;
  addressCity: string | null;
  addressZipCode: string | null;
  addressState: string | null;
  phone: string | null;
  invoiceable: boolean;
  optOutFromEmailCampaigns: boolean;
}

export interface UpdateUserAdminDTO extends UpdateUserRegularDTO {
  invoiceable?: boolean;
  optOutFromEmailCampaigns?: boolean;
  password?: string;
  companyId?: string | null;
}

export interface UpdateUserTokenDTO {
  registrationToken: string;
}

export interface UpdateUserRegularDTO {
  firstName?: string;
  agency?: string;
  lastName?: string;
  avatarUrl?: string;
  addressStreet?: string;
  addressUnit?: string;
  addressCity?: string;
  addressZipCode?: string;
  addressState?: string;
  phone?: string;
  optOutFromEmailCampaigns?: boolean;
}

export interface UserDTO {
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  optOutFromEmailCampaigns: boolean;
  agency: string | null;
  emailVerified: boolean;
  avatarUrl: string | null;
  companyId: string | null;
  company: CompanyDTO | null;
  role: UserRole;
  createdAt: Date;
  updatedAt: Date;
  lastActiveAt: Date | null;
  colorHex: string | null;
  addressStreet: string | null;
  addressUnit: string | null;
  addressCity: string | null;
  addressZipCode: string | null;
  addressState: string | null;
  phone: string | null;
  invoiceable: boolean;
  userStateSettings?: UserStateRatesDTO[];
}

export interface UpsertUserStateRatesDTO {
  filingFee?: FeeType | null;
  filingFeeValue?: number | null;
  filingFeeMinimum?: number | null;
  surplusTax?: Exclude<FeeType, FeeType.FLAT> | null;
  surplusTaxValue?: number | null;
  stampingFee?: FeeType | null;
  stampingFeeValue?: number | null;
  lgt?: boolean | null;
  mwua?: TaxType | null;
  mwuaValue?: number | null;
  fmt?: boolean | null;
  carriersFee?: boolean | null;
  carriersTaxable?: boolean | null;
  policyFee?: boolean | null;
  policyTaxable?: boolean | null;
}

export interface UpsertUserStateSettingsDTO {
  isStateVisible?: boolean | null;
  assignedUserId?: string | null;
}

export interface UsersResponse {
  items: UserDTO[];
  count: number;
}

export interface UserQueryDTO {
  q?: string;
  companyId?: string;
  role?: UserRole;
  sortBy?: UserSortBy;
  sortOrder?: 'ASC' | 'DESC';
  offset?: number;
  limit?: number;
}

export interface UserStateRatesDTO {
  id: string;
  filingStateId: string;
  filingState?: FilingStateDTO;
  userId: string;
  user?: UserDTO;
  surplusTaxRounded: boolean;
  stampingFeeRounded: boolean;
  filingFee: FeeType | null;
  filingFeeValue: number | null;
  filingType: 'nb' | 'ed';
  filingFeeMinimum: number | null;
  filingFeeMaximumEnabled: boolean | null;
  filingFeeMaximum: number | null;
  surplusTax: Exclude<FeeType, FeeType.FLAT> | null;
  surplusTaxValue: number | null;
  stampingFee: FeeType | null
  stampingFeeValue: number | null;
  lgt: TaxType | null;
  lgtValue?: number | null;
  mwua: TaxType | null;
  mwuaValue: number | null;
  fmt: TaxType | null;
  fmtValue?: number | null;
  carriersFee: boolean | null;
  carriersTaxable: boolean | null;
  policyFee: boolean | null;
  policyTaxable: boolean | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface UserStateSettingsDTO {
  id: string;
  filingStateId: string;
  filingState?: FilingStateDTO;
  isStateVisible: boolean | null;
  assignedUserId: string | null;
  assignedUser?: UserDTO | null;
  createdAt: Date;
  updatedAt: Date;
}
