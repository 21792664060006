import { UserStateRatesDTO } from 'dtos/user';

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID as string;

export const LANDING_IDS = {
  WHY_US: 'why-us',
  HOW_IT_WORKS: 'how-it-works',
  CONTACT: 'contact',
  SUBMIT_FILING: 'submit-filing'
};

export const buildLandingUrl = (sectionId: string) => {
  return `/#${sectionId}`;
}

export const ROUTES = {
  HOME: '/',
  ABOUT: '/about',
  CONTACT: '/contact',

  LOGIN: '/login',
  SIGNUP: '/signup',
  RESET_PASSWORD: '/reset-password',
  PROFILE: '/profile',
  FILING: '/filing/[state]',

  USER_DASHBOARD: '/user/dashboard',
  USER_PROFILE: '/user/profile',
  USER_FILING: '/user/filings/[id]',

  ADMIN_DASHBOARD: '/admin/dashboard',
  ADMIN_FILINGS: '/admin/filings',
  ADMIN_FILING: '/admin/filings/[id]',

  ADMIN_COMPANIES: '/admin/companies',
  ADMIN_COMPANY: '/admin/companies/[id]',
  ADMIN_GENERATE_INVOICE: '/admin/companies/generate-invoice/[id]',
  ADMIN_REGENERATE_INVOICE: '/admin/invoice/regenerate/[id]',

  ADMIN_USERS: '/admin/users',
  ADMIN_USER: '/admin/users/[id]',

  ADMIN_SETTINGS: '/admin/settings',
  ADMIN_FILLING_STATE_SETTINGS: '/admin/settings/[id]',

  ADMIN_REPORTS: '/admin/reports',
  ADMIN_REPORT: '/admin/reports/[id]',

  ADMIN_CLIENTS: '/admin/clients',
  ADMIN_CLIENT: '/admin/clients/[id]',

  ADMIN_LICENSES: '/admin/licenses'
};

export const CUSTOM_RATES_FIELDS: Array<keyof UserStateRatesDTO> = [
  'filingFee',
  'filingFeeValue',
  'filingFeeMinimum',
];
