import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useAuth } from 'context/AuthContext';
import { ROUTES } from 'common/constants';
import { useCurrentUser } from 'queries/useUser';
import { UserRole } from 'dtos/user';
import CustomErrorPage from 'pages/_error';

interface Props {
  children: React.ReactNode;
  requiredRoles: UserRole[];
}

const ProtectedRoute = ({ children, requiredRoles }: Props) => {
  const { isAuthenticated } = useAuth();
  const { data: user } = useCurrentUser();
  const router = useRouter();

  useEffect(() => {
    if (!isAuthenticated) {
      router.push(ROUTES.LOGIN);
    }
  }, [router, isAuthenticated]);

  if (isAuthenticated && user?.role) {
    if (requiredRoles.includes(user.role) || requiredRoles.length === 0) {
      return <>{children}</>;
    }

    if (!requiredRoles.includes(user.role)) {
      return <CustomErrorPage statusCode={403} title='Forbidden' />;
    }
  }

  return <></>;
};

export default ProtectedRoute;
