import { LicenseDTO } from "./license";
import { ReportStateDTO } from './report';

export enum ClientSortBy {
  NAME = 'name',
  EMAIL = 'email',
  COMPANY = 'company',
}

export interface ClientQuery {
  q?: string;
  sortBy?: ClientSortBy;
  sortOrder?: 'ASC' | 'DESC';
  offset?: number;
  isActive?: string;
  limit?: number;
}

export interface ClientDTO {
  id: string;
  firstName: string | null;
  lastName: string | null;
  company: string;
  isActive: boolean;
  phone: string | null;
  email: string | null;
  addressStreet: string | null;
  addressUnit: string | null;
  addressCity: string | null;
  addressZipCode: string | null;
  addressState: string | null;
  npn: string;
  fein: string;
  notes: string | null;
  licenses: LicenseDTO[];
  reportStates: ReportStateDTO[];
  createdAt: Date;
  updatedAt: Date;
}

export interface ClientsResponse {
  items: ClientDTO[];
  count: number;
}

export interface CreateClientDTO {
  firstName?: string;
  lastName?: string;
  company: string;
  phone?: string;
  isActive?: boolean;
  email?: string;
  addressStreet?: string;
  addressUnit?: string;
  addressCity?: string;
  addressZipCode?: string;
  addressState?: string;
  npn: string;
  fein: string;
  notes?: string;
}

export interface UpdateClientDTO {
  firstName?: string;
  lastName?: string;
  company?: string;
  isActive?: boolean;
  phone?: string;
  email?: string;
  addressStreet?: string;
  addressUnit?: string;
  addressCity?: string;
  addressZipCode?: string;
  addressState?: string;
  npn?: string;
  fein?: string;
  notes?: string;
  reportStateIds?: string[];
}
