import Link from 'next/link';
import Logo from 'public/logo.svg';
import { useAuth } from 'context/AuthContext';
import { useCurrentUser } from 'queries/useUser';
import { Button } from 'components/Button';
import { buildLandingUrl, LANDING_IDS, ROUTES } from 'common/constants';
import { useCallback, useMemo, useState } from 'react';
import PhoneIcon from 'public/assets/icons/phone-filled.svg';
import UserIcon from 'public/assets/icons/user.svg';
import cx from 'classnames';
import CloseIcon from 'public/assets/icons/close.svg';
import { UserRole } from 'dtos';
import { useRouter } from 'next/router';
import { Dropdown } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

interface ILink {
  href: string;
  label: string;
  dashboardOnly?: boolean;
  nonDashboardOnly?: boolean;
  adminOnly?: boolean;
  regularOnly?: boolean;
}

const LINKS: ILink[] = [
  { href: buildLandingUrl(LANDING_IDS.WHY_US), label: 'Why us', nonDashboardOnly: true },
  { href: buildLandingUrl(LANDING_IDS.HOW_IT_WORKS), label: 'How it works', nonDashboardOnly: true },
  { href: buildLandingUrl(LANDING_IDS.CONTACT), label: 'Contact', nonDashboardOnly: true },
  { href: buildLandingUrl(LANDING_IDS.WHY_US), label: 'Why us', dashboardOnly: true, regularOnly: true },
  { href: buildLandingUrl(LANDING_IDS.HOW_IT_WORKS), label: 'How it works', dashboardOnly: true, regularOnly: true },
  { href: buildLandingUrl(LANDING_IDS.CONTACT), label: 'Contact', dashboardOnly: true, regularOnly: true },
  { href: ROUTES.USER_DASHBOARD, label: 'Dashboard', regularOnly: true },
  { href: ROUTES.ADMIN_DASHBOARD, label: 'Dashboard', adminOnly: true },
  { href: ROUTES.ADMIN_FILINGS, label: 'Filings', adminOnly: true, dashboardOnly: true },
  { href: ROUTES.ADMIN_USERS, label: 'Users', adminOnly: true, dashboardOnly: true },
  { href: ROUTES.ADMIN_COMPANIES, label: 'Companies', adminOnly: true, dashboardOnly: true },
  { href: ROUTES.ADMIN_SETTINGS, label: 'Settings', adminOnly: true, dashboardOnly: true },
  { href: ROUTES.ADMIN_REPORTS, label: 'Reports', adminOnly: true, dashboardOnly: true },
  { href: ROUTES.ADMIN_CLIENTS, label: 'Clients', adminOnly: true, dashboardOnly: true },
  { href: ROUTES.ADMIN_LICENSES, label: 'Licenses', adminOnly: true, dashboardOnly: true }
];

export interface Props {
  className?: string;
  transparent?: boolean;
}

export const Navbar = ({ className, transparent }: Props) => {
  const router = useRouter();
  const activeRoute = router.pathname;
  const [open, setOpen] = useState(false);
  const { isAuthenticated, logout } = useAuth();
  const { data } = useCurrentUser({
    enabled: isAuthenticated
  });

  const isDashboard = useMemo(() => {
    const { pathname } = router;
    return pathname.startsWith('/user') || pathname.startsWith('/admin');
  }, [router]);

  const isAdmin = useMemo(() => data?.role === UserRole.ADMIN, [data]);
  const isRegular = useMemo(() => data?.role === UserRole.REGULAR, [data]);
  const links = useMemo(() => LINKS.filter((link) => {
    let meetsCriteria: boolean[] = [];
    if (link.adminOnly) {
      meetsCriteria.push(isAdmin);
    }

    if (link.regularOnly) {
      meetsCriteria.push(isRegular);
    }

    if (link.dashboardOnly) {
      meetsCriteria.push(isDashboard);
    }

    if (link.nonDashboardOnly) {
      meetsCriteria.push(!isDashboard);
    }

    return !meetsCriteria.some((c) => !c);
  }), [isAdmin, isDashboard, isRegular]);

  const renderLinks = useCallback(() => {
    return links.map((link) => (
      <Link
        href={link.href}
        key={link.href}
        className={cx('transition-all xl:text-lg text-white relative hover:text-accent', activeRoute === link.href && 'text-accent')}
        onClick={() => setOpen(false)}
      >
        {link.label}
        <div
          className={cx(
            'transition-all rounded absolute h-[2px] bg-accent left-1/2 -translate-x-1/2',
            activeRoute === link.href ? 'w-[60px] lg:w-1/2' : 'w-0'
          )}
        />
      </Link>
    ));
  }, [links, activeRoute]);

  const options: ItemType[] = useMemo(() => ([
    { label: 'Profile', key: 'profile', onClick: () => router.push(ROUTES.USER_PROFILE) },
    { label: 'Logout', key: 'logout', onClick: logout }
  ]), [logout, router]);

  return (
    <nav className={cx('z-10 flex px-4', className, transparent ? 'bg-transparent' : 'bg-main')}>
      <div className="container mx-auto flex gap-8">
        <Link href={ROUTES.HOME} className="min-w-[100px] md:w-[140px] lg:w-[180px] py-4">
          <Logo className=""/>
        </Link>
        <div className="hidden lg:flex items-center gap-8 color-white">
          {renderLinks()}
        </div>

        <div className='ml-auto flex gap-2'>
          <div className="flex gap-4 ml-auto items-center">
            {(!isAdmin || !isAuthenticated) && <Button variant="dimmed-white" className='hidden md:flex gap-2'>
              <PhoneIcon/> Call us
            </Button>}
            {isAuthenticated && data && (
              <Dropdown menu={{ items: options }}>
                <Button>
                  <UserIcon/> <span className="hidden xl:block">{data.firstName || data.email}</span>
                </Button>
              </Dropdown>
            )}
            {!isAuthenticated && <Link href={ROUTES.LOGIN}><Button>Login</Button></Link>}
          </div>

          <div className="lg:hidden flex align-center items-center">
            <button
              className="border-none bg-white/20 p-3 flex gap-1 flex-col rounded-md"
              onClick={() => setOpen(true)}
            >
              <div className="h-[2px] w-[22px] bg-white"/>
              <div className="h-[2px] w-[22px] bg-white"/>
              <div className="h-[2px] w-[22px] bg-white"/>
            </button>
          </div>
        </div>
      </div>

      <div
        className={cx('lg:hidden z-10 bg-main fixed top-0 left-0 h-screen w-screen transition-all', open ? 'pointer-events-auto' : 'pointer-events-none')}
        style={{
          transform: open ? 'translateX(0)' : 'translateX(100%)'
        }}
      >
        <div className="mt-4 mr-4">
          <button className="block ml-auto border-none bg-main p-2" onClick={() => setOpen(false)}>
            <CloseIcon className="block w-8 stroke-white"/>
          </button>
        </div>
        <div className="flex flex-col gap-8 text-center mt-10">
          <Link href={ROUTES.HOME} className="w-32 mx-auto">
            <Logo className=""/>
          </Link>
          {renderLinks()}
        </div>
      </div>
    </nav>
  );
};
