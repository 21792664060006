import { Navbar } from 'components/layouts/components/Navbar';
import { Footer } from 'components/layouts/components/Footer';
import cx from 'classnames';

interface Props {
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
  contentStyle?: React.CSSProperties;
  skipContainer?: boolean;
  navbarProps?: React.ComponentProps<typeof Navbar>;
  footerProps?: React.ComponentProps<typeof Footer>;
}

export const GeneralLayout = ({ children, className, contentClassName, footerProps, navbarProps, skipContainer, contentStyle }: Props) => {
  return (
    <div className={cx('min-h-screen flex flex-col', className)}>
      <Navbar {...navbarProps}/>
      <main
        style={contentStyle}
        className={cx(
        'flex-1',
        contentClassName,
        !skipContainer && 'py-4 md:py-10 container md:mx-auto px-4 md:px-0'
        )}
      >
        {children}
      </main>
      <Footer {...footerProps}/>
    </div>
  );
};
