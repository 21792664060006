import { US_STATES_ABBR_FOR_REPORTS } from "common/utils";
import { ClientDTO } from "./client";
import { LicenseDTO } from "./license";
import { UserDTO } from "./user";
import { IndividualReportDetailsDTO, ReportDetailsDTO } from "dtos/report-details";

export enum ReportPeriod {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  SEMI_ANNUALLY = 'semi_annually',
  ANNUALLY = 'annually',
}

export interface ClientsStatesReportsQueryDTO {
  period: ReportPeriod;
  periodIndex: number;
  year: number;
  assignedUserId?: string;
  clientId?: string;
  stateAbbr?: keyof typeof US_STATES_ABBR_FOR_REPORTS;
}

export interface ClientStatesReportsSingleReportStateDTO {
  reportStateId: string;
  licenses: LicenseDTO[];
  details: ReportDetailsDTO | null;
  individualDetails: IndividualReportDetailsDTO | null;
}

export interface ClientStatesReportsDTO {
  client: ClientDTO;
  reportStates: ClientStatesReportsSingleReportStateDTO[];
}

export interface ReportDTO {
  id: string;
  uploadedById: string;
  uploadedBy?: UserDTO;
  fileName: string | null;
  skipped: boolean;
  periodIndex: number;
  year: number;
  reportStateId: string;
  reportState?: ReportStateDTO;
  licenseId: string;
  license?: LicenseDTO;
  createdAt: Date;
  updatedAt: Date;
}

export interface ReportStateDTO {
  id: string;
  stateAbbr: string;
  comment: string | null;
  period: ReportPeriod;
  customPeriodIndices: number[] | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface UpdateReportStateDTO {
  comment?: string;
}

export enum ReportStateSortBy {
  PERIOD = 'period',
}

export interface ReportStateQueryDTO {
  period?: ReportPeriod;
  state?: string;
  sortBy?: ReportStateSortBy;
  sortOrder?: 'ASC' | 'DESC';
}

export interface CreateReportDTO {
  licenseId: string;
  reportStateId: string;
  year: number;
  periodIndex: number;
  skip?: boolean;
  fileName?: string;
  filePath?: string;
}

// ConsolidateReports
export interface ConsolidateReportsQueryDTO {
  periods: ReportPeriod[];
  year: number;
  clientId: string;
}

export interface GenerateUploadUrlDTO {
  licenseId: string;
  reportStateId: string;
  year: number;
  periodIndex: number;
  fileName: string;
}
