import { NextPageContext } from 'next';
import { ErrorProps } from 'next/error';
import { GeneralLayout } from 'components/layouts/GeneralLayout';

const CustomErrorPage = ({ statusCode, title }: ErrorProps) => {
  return (
    <GeneralLayout className=''>
      <h2 className='text-xl md:text-5xl text-center'>
        Something went wrong: {title || statusCode}
      </h2>
    </GeneralLayout>
  );
};

CustomErrorPage.getInitialProps = ({ res, err }: NextPageContext) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default CustomErrorPage;
