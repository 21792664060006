import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  CreateUserAdminDTO,
  CreateUserDTO, UpdateUserTokenDTO,
  UpdateUserAdminDTO, UpdateUserRegularDTO,
  UpsertUserStateRatesDTO,
  UserDTO,
  UserQueryDTO,
  UserStateRatesDTO,
  UsersResponse, UserStateSettingsDTO, UpsertUserStateSettingsDTO
} from 'dtos/user';
import UserService from 'services/User';
import * as Sentry from '@sentry/nextjs';

export const useCurrentUser = (options?: UseQueryOptions<UserDTO, AxiosError>) =>
  useQuery<UserDTO, AxiosError>(['current-user'], () => UserService.getMe(), {
    ...options,
    onSuccess: async (data) => {
      Sentry.setUser(data);

      if (options?.onSuccess) {
        options.onSuccess(data);
      }
    }
  });

export const useUser = (id: string, options?: UseQueryOptions<UserDTO, AxiosError>) =>
  useQuery<UserDTO, AxiosError>(['user', id], () => UserService.getById(id), {
    ...options
  });

export const useUsers = (query: UserQueryDTO) =>
  useQuery<UsersResponse>(['users', query], () => UserService.getAllUsers(query));

export const useUserStateRates = (id: string, options?: UseQueryOptions<UserStateRatesDTO[], AxiosError>) =>
  useQuery<UserStateRatesDTO[], AxiosError>(['user-state-rates', id], () => UserService.getUserStateRatesById(id), options);

export const useUserStateSettings = (id: string, options?: UseQueryOptions<UserStateSettingsDTO[], AxiosError>) =>
  useQuery<UserStateSettingsDTO[], AxiosError>(['user-state-settings', id], () => UserService.getUserStateSettingsById(id), options);

export const useUpsertUserStateRates = (
  userId: string,
  options?: UseMutationOptions<UserStateRatesDTO, AxiosError, UpsertUserStateRatesDTO & { filingStateId: string, type: 'ed' | 'nb' }>
) => {
  const queryUserSettings = useQueryClient();

  return useMutation(
    ['upsert-user-state-rates', userId],
    ({ filingStateId, type, ...data }: UpsertUserStateRatesDTO & { filingStateId: string, type: 'ed' | 'nb' }) =>
      UserService.upsertUserStateRatesById(userId, filingStateId, type, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryUserSettings.invalidateQueries(['user-state-rates', userId]);
        await queryUserSettings.invalidateQueries(['users']);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      }
    }
  );
};

export const useTriggerVerificationEmail = (
  options?: UseMutationOptions<void, AxiosError, void>
) => useMutation(
  ['trigger-verification-email'],
  () => UserService.triggerSendingVerificationEmail(),
  options
);

export const useUpsertUserStateSettings = (
  userId: string,
  options?: UseMutationOptions<UserStateSettingsDTO, AxiosError, UpsertUserStateSettingsDTO & { filingStateId: string }>
) => {
  const queryUserSettings = useQueryClient();

  return useMutation(
    ['upsert-user-state-settings', userId],
    ({ filingStateId, ...data }: UpsertUserStateSettingsDTO & { filingStateId: string }) =>
      UserService.upsertUserStateSettingsById(userId, filingStateId, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryUserSettings.invalidateQueries(['user-state-settings', userId]);
        await queryUserSettings.invalidateQueries(['users']);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      }
    }
  );
};

export const useCreateUserAdmin = (
  options?: UseMutationOptions<UserDTO, AxiosError, CreateUserAdminDTO>
) => {
  const queryUserSettings = useQueryClient();

  return useMutation(
    ['create-user'],
    (data: CreateUserAdminDTO) => UserService.createUserAdmin(data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryUserSettings.invalidateQueries(['users']);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      }
    }
  );
};

export const useUpdateUserAdmin = (
  options?: UseMutationOptions<UserDTO, AxiosError, UpdateUserAdminDTO & { userId: string }>
) => {
  const queryUserSettings = useQueryClient();

  return useMutation(
    ['update-user'],
    ({ userId, ...data }: UpdateUserAdminDTO & { userId: string }) => UserService.updateAdminUserById(userId, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryUserSettings.invalidateQueries(['user', data.id]);
        await queryUserSettings.invalidateQueries(['users']);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      }
    }
  );
};

export const useUpdateMe = (
  options?: UseMutationOptions<UserDTO, AxiosError, UpdateUserRegularDTO>
) => {
  const queryUserSettings = useQueryClient();

  return useMutation(
    ['update-user-me'],
    (data: UpdateUserRegularDTO) => UserService.updateMe(data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryUserSettings.invalidateQueries(['user', data.id]);
        await queryUserSettings.invalidateQueries(['current-user']);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      }
    }
  );
};

export const useUpdateMeToken = (
  options?: UseMutationOptions<UserDTO, AxiosError, UpdateUserTokenDTO>
) => {
  const queryUserSettings = useQueryClient();

  return useMutation(
    ['update-user-me-token'],
    (data: UpdateUserTokenDTO) => UserService.updateMeToken(data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryUserSettings.invalidateQueries(['user', data.id]);
        await queryUserSettings.invalidateQueries(['current-user']);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      }
    }
  );
};

export const useSignup = (
  options?: UseMutationOptions<UserDTO, AxiosError, CreateUserDTO>
) => {
  return useMutation(
    ['signup'],
    (data: CreateUserDTO) => UserService.signup(data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      }
    }
  );
};

export const useForgotPassword = (
  options?: UseMutationOptions<void, AxiosError<{ message: string}>, string>
) => {
  return useMutation(
    ['forgot-password'],
    (email: string) => UserService.requestPasswordReset(email),
    options
  );
};

