import { UserDTO } from 'dtos/user';
import { InvoiceDTO } from 'dtos/invoice';

export enum FilingType {
  NEW_BUSINESS = 'NEW_BUSINESS',
  NEW_BUSINESS_RENEWAL = 'NEW_BUSINESS_RENEWAL',
  ENDORSEMENT_ADDITIONAL = 'ENDORSEMENT_ADDITIONAL',
  ENDORSEMENT_RETURN = 'ENDORSEMENT_RETURN',
}

export interface StateRatesSnapshot {
  filingFee: Exclude<FeeType, FeeType.PERCENTAGE_WITH_FEES_VIA>;
  filingFeeValue: number;
  filingFeeMinimum: number;
  surplusTaxRounded: boolean;
  stampingFeeRounded: boolean;
  filingFeeMaximumEnabled: boolean | null;
  filingFeeMaximum: number | null;
  surplusTax: Exclude<FeeType, FeeType.FLAT>;
  surplusTaxValue: number;
  stampingFee: FeeType;
  stampingFeeValue: number;
  lgt: TaxType;
  lgtValue: number;
  mwua: TaxType;
  mwuaValue: number;
  fmt: TaxType;
  fmtValue: number;
  carriersFee: boolean;
  carriersTaxable: boolean;
  policyFee: boolean;
  policyTaxable: boolean;
}

export enum FilingStatus {
  UNPAID = 'UNPAID',
  PAID = 'PAID',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export enum PaymentMethod {
  ACH = 'ACH',
  WIRE = 'WIRE',
  CHECK = 'CHECK',
  PENDING_INVOICE = 'PENDING_INVOICE',
  INVOICED = 'INVOICED',
}

export interface UploadUserFileDTO {
  fileName: string;
  filingId?: string;
}

export interface UploadAdminFileDTO {
  fileName: string;
  filingId: string;
}

export interface UploadStateFileDTO {
  fileName: string;
  filingStateId: string;
}

export interface CreateFilingFileDTO {
  filingId: string;
  name: string;
  fileName: string;
  filePath: string;
}

export interface CreateFilingFileInternalDTO extends CreateFilingFileDTO {
  notes?: string;
}

export enum UploadFileStateType {
  ED = 'EN',
  NB = 'NB',
}

export interface FilesNotificationDTO {
  filingId: string;
  fileIds: string[];
}

export interface CreateFilingStateFileDTO {
  filingStateId: string;
  type: UploadFileStateType;
  name: string;
  fileName: string | null;
  filePath: string | null;
}

export interface CreateFilingFileWhileCreationDTO {
  name: string;
  fileName: string;
  filePath: string;
}

export interface CreateFilingDTO {
  filingType: FilingType;
  filingStateId: string;
  policyNumber: string;
  userId?: string;
  endorsementNumber?: string;
  insuredsName: string;
  carriersName: string;
  policyEffectiveDate: Date;
  endorsementEffectiveDate?: Date;
  premium: number;
  policyFee?: number;
  inspectionFee: number;
  carriersFee?: number;
  lgtValue?: number;
  fmtValue?: number;
  declaredPaymentMethod?: PaymentMethod;
  userNotes?: string;
  files: CreateFilingFileWhileCreationDTO[];
}

export type FilingCalculationFields = Partial<Pick<
  FilingDTO,
  'premium' | 'policyFee' | 'inspectionFee' | 'carriersFee' | 'wireFee' | 'lateFee' | 'lgtValue' | 'fmtValue'
>>;

export interface FilingDTO {
  id: string;
  filingType: FilingType;
  userId: string;
  rejectionReason: string | null;
  user?: UserDTO;
  filingStateId: string;
  filingState?: FilingStateDTO;
  policyNumber: string;
  endorsementNumber: string | null;
  insuredsName: string;
  carriersName: string;
  policyEffectiveDate: Date | null;
  endorsementEffectiveDate: Date | null;
  premium: number;
  policyFee: number | null;
  inspectionFee: number;
  carriersFee: number | null;
  nbStateRatesSnapshot: StateRatesSnapshot;
  edStateRatesSnapshot: StateRatesSnapshot;
  status: FilingStatus;
  declaredPaymentMethod: PaymentMethod | null;
  finalPaymentMethod: PaymentMethod | null;
  userNotes: string | null;
  internalNotes?: string | null;
  invoiceNotes: string | null;
  lateFee: number | null;
  wireFee: number | null;
  lgtValue: number;
  fmtValue: number;
  completedAt: Date | null;
  invoiceId: string | null;
  invoice: InvoiceDTO | null;
  userFiles?: FilingStateFileDTO[];
  viaFiles?: ViaFilingFileDTO[];
  internalFiles?: ViaFilingFileDTO[];
  assignedUser?: UserDTO | null;
  assignedUserId: string | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface UpdateFilingDTO {
  filingType?: FilingType;
  policyNumber?: string;
  rejectionReason?: string | null;
  endorsementNumber?: string;
  insuredsName?: string;
  carriersName?: string;
  policyEffectiveDate?: Date;
  endorsementEffectiveDate?: Date;
  premium?: number;
  policyFee?: number;
  inspectionFee?: number;
  carriersFee?: number;
  status?: FilingStatus;
  declaredPaymentMethod?: PaymentMethod;
  finalPaymentMethod?: PaymentMethod;
  userNotes?: string;
  invoiceNotes?: string;
  internalNotes?: string;
  lateFee?: number;
  wireFee?: number;
  lgtValue?: number;
  fmtValue?: number;
  completedAt?: Date;
  assignedUserId?: string | null;
}

export enum FilingSortBy {
  USER_NAME = 'user_name',
  INSUREDS_NAME = 'insureds_name',
  CREATION_DATE = 'creation_date',
  STATE = 'state',
  STATUS = 'status',
  PAYMENT_METHOD = 'payment_method',
}

export interface FilingQueryDTO {
  q?: string;
  createdAfter?: string;
  createdBefore?: string;
  completedBefore?: string;
  filingStateId?: string;
  status?: FilingStatus;
  assignedUserId?: string;
  userId?: string;
  companyId?: string;
  paymentMethod?: PaymentMethod;
  sortBy?: FilingSortBy;
  sortOrder?: 'ASC' | 'DESC';
  offset?: number;
  limit?: number;
}

export interface FilingsResponseDTO {
  count: number;
  items: FilingDTO[];
}

export interface DashboardDataDTO {
  userPaidFilingsCount: number;
  userPendingFilingsCount: number;
  userCompletedFilingsCount: number;
  allUnassignedFilingsCount: number;
  unpaidAchFilingsCount: number;
  unpaidWireFilingsCount: number;
  unpaidCheckFilingsCount: number;
  totalFilingsCount: number;
}

export interface FilingStateQueryDTO {
  state?: string;
  assignedUserId?: string;
}

export interface UserStateRatesQueryDTO {
  userId?: string;
}

export interface UserStateSettingsQueryDTO {
  userId?: string;
}

export interface ContactDTO {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}


export interface StateRatesDTO {
  id: string;
  filingFee: Exclude<FeeType, FeeType.PERCENTAGE_WITH_FEES_VIA>;
  filingFeeValue: number;
  filingFeeMinimum: number;
  surplusTax: Exclude<FeeType, FeeType.FLAT>;
  surplusTaxValue: number;
  stampingFee: FeeType;
  stampingFeeValue: number;
  surplusTaxRounded: boolean;
  stampingFeeRounded: boolean;
  lgt: TaxType;
  lgtValue: number;
  mwua: TaxType;
  mwuaValue: number;
  fmt: TaxType;
  fmtValue: number;
  carriersFee: boolean;
  carriersTaxable: boolean;
  policyFee: boolean;
  policyTaxable: boolean;
  filingFeeMaximumEnabled: boolean | null;
  filingFeeMaximum: number | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface StateSettingsDTO {
  id: string;
  filingStateId: string;
  filingState?: FilingStateDTO;
  isStateVisible: boolean;
  assignedUserId: string | null;
  assignedUser?: UserDTO | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface FilingStateFileDTO {
  id: string;
  name: string;
  fileName: string | null;
  filePath: string | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface ViaFilingFileDTO extends FilingStateFileDTO {
  uploadedByUserId: string;
  uploadedBy?: UserDTO | null;
  notes?: string;
}

export interface InternalFilingFileDTO extends FilingStateFileDTO {
  uploadedByUserId: string;
  uploadedBy?: UserDTO | null;
  notes?: string;
}

export interface UpdateFilingStateDTO {
  descriptionNb?: string;
  descriptionEd?: string;
}

export interface FilingStateDTO {
  id: string;
  name: string;
  stateAbbr: string;
  descriptionNb: string;
  descriptionEd: string;
  filesNb?: FilingStateFileDTO[];
  filesEd?: FilingStateFileDTO[];
  stateSettings?: StateSettingsDTO;
  nbStateRates?: StateRatesDTO;
  edStateRates?: StateRatesDTO;
  createdAt: Date;
  updatedAt: Date;
}

export enum FeeType {
  OFF = 'OFF',
  FLAT = 'FLAT',
  PERCENTAGE = 'PERCENTAGE',
  PERCENTAGE_WITH_FEES = 'PERCENTAGE_WITH_FEES',
  PERCENTAGE_WITH_FEES_VIA = 'PERCENTAGE_WITH_FEES_VIA',
}

export enum TaxType {
  OFF = 'OFF',
  PERCENTAGE = 'PERCENTAGE',
  PERCENTAGE_WITH_FEES = 'PERCENTAGE_WITH_FEES',
  PERCENTAGE_WITH_FEES_VIA = 'PERCENTAGE_WITH_FEES_VIA',
  MANUAL = 'MANUAL',
}

export interface UpdateStateRatesDTO {
  filingFee?: Exclude<FeeType, FeeType.PERCENTAGE_WITH_FEES_VIA>;
  filingFeeValue?: number;
  filingFeeMinimum?: number;
  surplusTax?: Exclude<FeeType, FeeType.FLAT>;
  surplusTaxValue?: number;
  stampingFee?: FeeType;
  stampingFeeValue?: number;
  lgt?: TaxType;
  lgtValue?: number;
  mwua?: TaxType;
  mwuaValue?: number;
  fmt?: TaxType;
  fmtValue?: number;
  carriersFee?: boolean;
  carriersTaxable?: boolean;
  policyFee?: boolean;
  policyTaxable?: boolean;
}


export interface UpdateStateSettingsDTO {
  isStateVisible?: boolean;
  assignedUserId?: string | null;
}
