import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { auth } from 'config/firebase';
import * as rax from 'retry-axios';

import {
  loadAuthTokenFromLocalStorage,
  removeAuthTokenFromStorage,
  saveAuthTokenInLocalStorage
} from 'services/Tokens';
import { ROUTES } from 'common/constants';

class _RequestService {
  client: AxiosInstance;
  
  constructor() {
    this.client = axios.create({
      baseURL: process.env.NEXT_PUBLIC_API_URL,
      maxContentLength: Infinity,
      maxBodyLength: Infinity
    });

    this.setRetry();
    this.setAuthenticationInterceptor();
    this.setReauthenticateInterceptor();
  }
  
  setRetry() {
    rax.attach(this.client);
  }
  
  setAuthenticationInterceptor() {
    this.client.interceptors.request.use((config: AxiosRequestConfig) => {
      const token = loadAuthTokenFromLocalStorage();
      
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`
        };
      }
      
      return config;
    });
  }
  
  setReauthenticateInterceptor() {
    this.client.interceptors.response.use(
      undefined,
      async (error: unknown) => {
        if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
          if(auth.currentUser) {
            const token = await auth.currentUser.getIdToken(true);
            saveAuthTokenInLocalStorage(token);
            return this.client.request(error.config);
          } else {
            removeAuthTokenFromStorage();
            window.location.href = ROUTES.LOGIN;
          }
        }
        
        throw error;
      }
    );
  }
}

const RequestService = new _RequestService();

export default RequestService;
