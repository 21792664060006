import Logo from 'public/logo.svg';
import PhoneIcon from 'public/assets/icons/phone.svg';
import LocationIcon from 'public/assets/icons/location.svg';
import EnvelopeIcon from 'public/assets/icons/envelope.svg';
import Link from 'next/link';
import cx from 'classnames';
import { buildLandingUrl, LANDING_IDS, ROUTES } from 'common/constants';

interface Props {
  className?: string;
}

export const Footer = ({ className }: Props) => {
  return (
    <footer
      className={cx('bg-main text-white py-14 md:py-10 border-2 border-t-gray3 border-x-0 border-b-0 border-solid', className)}>
      <div className="container px-4 md:px-0 mx-auto flex justify-between flex-col md:flex-row">
        <div className='order-3 md:order-1 mt-20 md:mt-0'>
          <Logo className='mb-2 w-[160px]'/>
          <p>
            Valley Insurance Associates, LLC<br/>
            P.O. Box 1973, Sumner, WA 98390
          </p>

          <p className="opacity-50 mt-6 text-xs md:text-md">Copyright {new Date().getFullYear()} Valley Insurance Associates, LLC</p>
        </div>
        <div className="order-2 flex flex-col md:flex-row gap-14 md:gap-20">
          <div className="flex flex-col">
            <h3 className="mb-4 text-2xl font-normal">Contact us</h3>
            <div className="flex flex-col gap-3">
              <div className="flex gap-2 items-center">
                <div className='w-6 flex items-center justify-center'>
                  <EnvelopeIcon className="h-4 fill-white"/>
                </div>
                PO Box 1973, Sumner, WA 98390
              </div>
              <div className="flex gap-2">
                <div className='w-6 flex items-center justify-center'>
                  <LocationIcon className="h-4 fill-white"/>
                </div>
                307 29th St. NE #107 Puyallup, WA 98372
              </div>
              <a href="tel:2534663296" className="flex gap-2 text-white">
                <div className='w-6 flex items-center justify-center'>
                  <PhoneIcon className="h-4 text-center stroke-white"/>
                </div>
                253-466-3296
              </a>
            </div>
          </div>
          <div className="flex flex-col">
            <h3 className="mb-4 text-2xl font-normal">Find more</h3>
            <div className="flex flex-col gap-3">
              <Link href={ROUTES.HOME} className='text-white'>Home page</Link>
              <Link href={buildLandingUrl(LANDING_IDS.CONTACT)} className='text-white'>Contact</Link>
              <Link href={buildLandingUrl(LANDING_IDS.SUBMIT_FILING)} className='text-white'>Submit Filing</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
