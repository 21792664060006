// Styles
import 'antd/dist/antd.css';
import 'styles/globals.scss';

// Fonts
import '@fontsource/poppins';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

import '@fontsource/playfair-display';
import '@fontsource/playfair-display/500.css';
import '@fontsource/playfair-display/600.css';
import '@fontsource/playfair-display/700.css';

// Regular imports
import type { AppProps } from 'next/app';
import { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ProtectedRoute from 'components/ProtectedRoute';
import { AuthContextProvider } from 'context/AuthContext';
import Head from 'next/head';

import { GA_TRACKING_ID } from 'common/constants';
import { useRouter } from 'next/router';

const handleRouteChange = (url: URL) => {
  if (GA_TRACKING_ID && window && window.gtag) {
    window.gtag('config', GA_TRACKING_ID, { page_path: url });
  }
};

function MyApp({Component, pageProps}: AppProps) {
  const [queryClient] = useState(() => new QueryClient());

  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <meta charSet="utf-8"/>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
        <link rel="manifest" href="/site.webmanifest"/>
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="theme-color" content="#ffffff"/>
        <title>Surplus Lines Tax Filing | Valley Insurance Associates, LLC</title>
        <meta name="description" content="We specialize in the processing and administration of surplus lines premium tax filings."/>
        <meta name="keywords" content="Insurance, Surplus Lines Broker, Surplus Lines Courtesy Filing, Insurance Tax Reporting Service, Gina Doyle, Surplus Lines Filing Requirements, Premium Tax, Melinda Craig, Courtesy Filings, Surplus Lines Producer Tax Report, Courtesy Filings Provider, Insurance Tax Service, Courtesy Tax Filing, Surplus Lines State Tax, Excess Lines Tax, Surplus Lines Insurance"/>
        <meta name="copyright" content={`© Copyright ${new Date().getFullYear()} Valley Insurance Associates, LLC`}/>
        <meta name="location" content="United States, Washington, California, Colorado, Connecticut, Washington, DC, Nevada, Georgia, Hawaii, Minnesota, Michigan, Illinois, Indiana, Kansas, Kentucky, Louisiana"/>
        <meta name="Author" content="The Digital Bunch, alex@thedigitalbunch.com"/>

        <meta property="og:url" content="https://valleyinsllc.com/"/>
        <meta property="og:type" content="website"/>
        <meta property="og:site_name" content="Valley Insurance Associates, LLC"/>
        <meta property="og:country-name" content="USA"/>
        <meta property="og:email" content="gina@valleyinsllc.com"/>
        <meta property="og:phone_number" content="253-466-3296"/>
        <meta property="og:title" content="Surplus Lines Tax Filing | Valley Insurance Associates, LLC"/>
        <meta property="og:description" content="We specialize in the processing and administration of surplus lines premium tax filings."/>
        <meta property="og:image" content="/og-image.png"/>

        {GA_TRACKING_ID && (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${GA_TRACKING_ID}', {
                    page_path: window.location.pathname,
                  });
                `,
              }}
            />
          </>
        )}
      </Head>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          {!pageProps.isProtected ? (
            <Component {...pageProps} />
          ) : (
            <ProtectedRoute requiredRoles={pageProps.requiredRoles}>
              <Component {...pageProps} />
            </ProtectedRoute>
          )}
        </AuthContextProvider>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
