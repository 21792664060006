import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  UserCredential,
  getMultiFactorResolver,
  MultiFactorResolver,
  RecaptchaVerifier, PhoneAuthProvider, PhoneMultiFactorGenerator,
} from 'firebase/auth';
import { auth } from 'config/firebase';
import {
  loadAuthTokenFromLocalStorage,
  removeAuthTokenFromStorage,
  saveAuthTokenInLocalStorage,
} from 'services/Tokens';
import { useQueryClient } from '@tanstack/react-query';

export interface Authentication {
  email: string;
  password: string;
}

export interface ResetPassword {
  email: string;
}

interface AuthContextValue {
  isAuthenticated: boolean;
  login: (authentication: Authentication) => Promise<UserCredential | void>;
  logout: () => Promise<void>;
  signup: (authentication: Authentication) => Promise<UserCredential | void>;
  resetPassword: (resetPassword: ResetPassword) => Promise<void>;
  handleTwoFactorSubmit: (code: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextValue>({
  isAuthenticated: false,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  signup: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  handleTwoFactorSubmit: () => Promise.resolve(),
});

export const useAuth = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(loadAuthTokenFromLocalStorage() !== null);
  const resolver = useRef<MultiFactorResolver | null>(null);
  const recaptchaVerifier = useRef<RecaptchaVerifier | null>(null);
  const verificationId = useRef<string | null>(null);

  const refreshAuthState = () => {
    setIsAuthenticated(loadAuthTokenFromLocalStorage() !== null);
  };

  const handleTwoFactor = async () => {
    if (!resolver.current) {
      throw new Error('Resolver is not set');
    }

    if (!recaptchaVerifier.current) {
      console.log('🦑', 'No recaptchaVerifier found, creating a new one')
      recaptchaVerifier.current = new RecaptchaVerifier('recaptcha-container-id', {}, auth);
    }

    const phoneInfoOptions = {
      multiFactorHint: resolver.current.hints[0],
      session: resolver.current.session
    };

    const phoneAuthProvider = new PhoneAuthProvider(auth);
    console.log('🦑', 'Trying to verify phone number', phoneInfoOptions);
    verificationId.current = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier.current)
    console.log('🦑', 'Verification ID', verificationId.current)
  }

  const handleTwoFactorSubmit = async (code: string) => {
    if (!verificationId.current) {
      throw new Error('Verification ID is not set');
    }

    const cred = PhoneAuthProvider.credential(verificationId.current, code);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

    if (resolver.current) {
      await resolver.current.resolveSignIn(multiFactorAssertion)
    }
  }

  const logout = useCallback(async () => {
    queryClient.invalidateQueries(['current-user'], {}, { cancelRefetch: true });
    removeAuthTokenFromStorage();
    await signOut(auth);
  }, [queryClient]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        setLoading(true);
        firebaseUser
          .getIdToken()
          .then((token) => {
            saveAuthTokenInLocalStorage(token);
            refreshAuthState();
          })
          .catch((err) => {
            logout();
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        logout();
      }

      refreshAuthState();
      setLoading(false);
    });

    return () => unsubscribe();
  }, [logout]);

  const signup = ({ email, password }: Authentication) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const login = async ({ email, password }: Authentication) => {
    try {
      if (recaptchaVerifier.current) {
        console.log('🦑', 'Clearing recaptchaVerifier');
        recaptchaVerifier.current.clear();
        return await handleTwoFactor();
      }

      return await signInWithEmailAndPassword(auth, email, password)
    } catch (err: any) {
      if (err.code === 'auth/multi-factor-auth-required') {
        console.log('🦑', 'Multi-factor auth required');
        resolver.current = getMultiFactorResolver(auth, err);
        await handleTwoFactor();

        throw err;
      } else {
        throw err;
      }
    }
  };

  const resetPassword = ({ email }: ResetPassword) => {
    return sendPasswordResetEmail(auth, email);
  };

  return (
    <AuthContext.Provider value={{ login, signup, logout, isAuthenticated, resetPassword, handleTwoFactorSubmit }}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
