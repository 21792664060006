import { ClientDTO } from "./client";
import { ReportDTO, ReportStateDTO } from "./report";

export enum LicenseSortBy {
  COMPANY = 'company',
  CLIENT_NAME = 'client_name',
  LICENSE_NUMBER = 'license_number',
  LICENSEE = 'licensee',
  STATE = 'state',
  EFFECTIVE_DATE = 'effective_date',
  STATUS = 'status',
}

export interface LicenseQueryDTO {
  q?: string;
  state?: string;
  isActive?: boolean;
  clientId?: string;
  sortBy?: LicenseSortBy;
  sortOrder?: 'ASC' | 'DESC';
  offset?: number;
  limit?: number;
}

export interface LicenseDTO {
  id: string;
  licensee: string;
  licenseNumber: string;
  login: string;
  password: string;
  notes: string | null;
  effectiveDate: Date | null;
  isActive: boolean;
  stateAbbr: string;
  state?: ReportStateDTO;
  clientId: string;
  client?: ClientDTO;
  reports?: ReportDTO[];
  createdAt: Date;
  updatedAt: Date;
}

export interface LicensesResponse {
  items: LicenseDTO[];
  count: number;
}

export interface CreateLicenseDTO {
  licensee: string;
  licenseNumber: string;
  login: string;
  password: string;
  notes?: string;
  effectiveDate?: Date;
  stateAbbr: string;
  clientId: string;
  isActive?: string | boolean;
}

export interface BulkUpdateLicenseDTO extends UpdateLicenseDTO {
  ids: string[];
}

export interface UpdateLicenseDTO {
  licensee?: string;
  licenseNumber?: string;
  login?: string;
  password?: string;
  notes?: string;
  effectiveDate?: Date | null;
  stateAbbr?: string;
  clientId?: string;
  isActive?: string | boolean;
}
